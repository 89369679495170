


@media (max-width: 640px) {


    /* Home page */

    .home-head{
        margin-top: 30px;
        margin-left: 5px;

    }

    .home-button{
        margin-left: 30px;
        width: 100px;
    }
    .home-button-text{
        margin-left: 30px;
        width: 100px;
    }

    .home-head-line1{
        margin-left: 10px;
        margin-bottom: 5px;
    }

    .home-head-line2{
        font-size: 95%;
        margin-top: 10px;
    }

    .homeimg-shift-right {
      margin-left: 10px; /* Adjust the value as needed */
    }

    /* Login Page */
    
    .hide-on-mobile {
        display: none;
      }
    
      .login-card {
        width: 180% !important;
        margin-top: 50px !important;
        margin-bottom: 100px !important;
        padding-top: 0 !important;
      }
      .login-card-width{
        padding: 0;
        margin: 0;
      }
      
      .login-card-last-text{
        width: 100%;
        display: block;
        margin-top: 15px;
      }

      /* Sign Up */

      .signup-card {
        width: 145% ;
        margin-top: 5 !important;
        padding: 0px !important;
        /* background-color: aqua; */
        margin-left: -33px;
      }

      .signup-image{
        display: none;
      }

      .signup-card-last-text{
        margin-top: 20px;
      }


    }



    